.table-visible-blue {
  display: none;
  background-color: #f6f6f6;
}

.table-visible-blue.show {
  display: block;
}

.table-visible-blue table {
  border: 1px solid #ccc;
}

.table-visible-blue table th {
  background-color: #f6f6f6;
}

.table-visible-green {
  display: table-row;
  background-color: rgb(176, 241, 176);
}

.flip-ver {
  transform: rotate(180deg);
}

.collapse-button:hover {
  cursor: pointer;
}

.table-hidden {
  display: none;
}

.fa-check {
  font-size: 18px;
}

.d-t-inline {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  height: 30px;
  vertical-align: top;
  padding: 0;
}

.cust-fa {
  display: block;
  height: 30px;
  line-height: 15px;
  border: 1px solid;
}
